<template>
  <div>
    <infoHeader :content_name="'更改合作方主账户详细信息和配置'" />

    <el-row :gutter="50">
      <el-col :span="8">
        <!-- <el-descriptions title="账户信息"></el-descriptions> -->
        <el-form class="demo-form-inline">
          <el-form-item label="用户名">
            <el-input v-model="data.name"></el-input>
          </el-form-item>

          <el-form-item label="邮箱">
            <el-input v-model="data.email"></el-input>
          </el-form-item>

          <el-form-item label="手机号">
            <el-input v-model="data.phone"></el-input>
          </el-form-item>

          <el-form-item label="选择账户状态">
            <el-select v-model="data.status" placeholder="请选择">
              <el-option
                v-for="item in data.status_dict"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="updateFirstLevel">提交更改</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="16">
        <template slot="extra">
          <el-button type="primary" size="small">管理关联</el-button>
        </template>

        <el-table :data="tableRelevanceData" style="width: 100%">
          <el-table-column prop="garage.info_name" label="车库名" width="180">
          </el-table-column>
          <el-table-column prop="garage.garage_id" label="车库ID" width="200">
          </el-table-column>
          <el-table-column prop="garage.info_address" label="车库地址"> </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <div class="layoutBorder">
      <el-card shadow="hover">
        <div slot="header" class="clearfix">
          <span>该合作方账户下的所有子账户</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="createSecondLevelButton()"
            >新增子账户</el-button
          >
        </div>
        <el-table :data="data.tableSubAccountData" style="width: 100%">
          <el-table-column prop="name" label="账户名"> </el-table-column>
          <el-table-column prop="email" label="邮箱"> </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column label="更多" fixed="right" width="140">
            <template slot-scope="scope">
              <el-button @click="editSecondLevelButton(scope.row)">账户编辑</el-button>
              <!-- <el-button @click="delSecondLevel(scope.row.id)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-dialog title="编辑合作方子账户" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="名字" label-width="120px">
          <el-input v-model="formEdit.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="120px">
          <el-input v-model="formEdit.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="重复密码密码" label-width="120px">
          <el-input v-model="formEdit.repassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" label-width="120px">
          <el-input v-model="formEdit.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" label-width="120px">
          <el-input v-model="formEdit.phone" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateSecondLevel()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增合作方子账户" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="名字" label-width="120px">
          <el-input v-model="formAdd.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="120px">
          <el-input
            show-password
            v-model="formAdd.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码密码" label-width="120px">
          <el-input
            show-password
            v-model="formAdd.repassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" label-width="120px">
          <el-input v-model="formAdd.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" label-width="120px">
          <el-input v-model="formAdd.phone" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  partner_account_update,
  partner_children_account_query,
  partner_chirdren_account_create,
  partner_children_account_query_edit,
} from "@/api/manage/business/partner_account/partner_account.js";

export default {
  name: "start",
  data() {
    return {
      dialogFormVisibleEdit: false,
      formEdit: {},
      dialogFormVisibleAdd: false,
      formAdd: {
        name: "",
      },
      tableRelevanceData: [],
      data: {
        status_dict: [
          { value: 1, label: "正常" },
          { value: 2, label: "禁用" },
          { value: 3, label: "黑名单" },
        ],
        tableSubAccountData: [],
        name: null,
        email: null,
        phone: null,
        status: null,
      },
      variate: {},
      status: {},
    };
  },
  components: {},
  created() {
    // 拼接一级更改信息
    this.data.name = this.$route.params.name;
    this.data.email = this.$route.params.email;
    this.data.phone = this.$route.params.phone;
    this.data.status = this.$route.params.status;
    console.log(this.$route.params);
    // 拿到二级信息
    this.getSecondLevel();
  },
  methods: {
    updateSecondLevel() {
      console.log(this.formEdit, "edit");
      this.formEdit["sub_account_id"] = this.formEdit["id"];
      console.log(this.formEdit,"xxxxxxxxxxxxx")
      partner_children_account_query_edit
      this.$confirm("是否确定更改合作方子项目", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "更改",
        cancelButtonText: "放弃添加",
      })
        .then(() => {
          partner_children_account_query_edit(this.formEdit).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功更改");
              this.$router.push({ name: "partnerEditAccount" });
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃更改" : "停留在当前",
          });
        });
    },
    editSecondLevelButton(e) {
      this.dialogFormVisibleEdit = true;
      console.log(e)
      this.formEdit = e;
    },
    createFirstLevel() {
      this.formAdd["primary_account_id"] = this.$route.params.id;

      this.$confirm("是否确定添加合作方子项目", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "更改",
        cancelButtonText: "放弃添加",
      })
        .then(() => {
          partner_chirdren_account_create(this.formAdd).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功添加");
              this.$router.push({ name: "partnerEditAccount" });
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃添加" : "停留在当前",
          });
        });
    },
    createSecondLevelButton() {
      this.dialogFormVisibleAdd = true;
    },
    editSecondLevel() {},
    getSecondLevel() {
      let query = {
        primary_account_id: this.$route.params.id,
        querypage: 1, //获取页码
        pagesize: 100, //每页返回数量
      };
      partner_children_account_query(query).then((res) => {
        console.log(res, "这里的data");
        this.data.tableSubAccountData = res.data.result;
      });
    },
    updateFirstLevel() {
      let temp = {
        account_id: this.$route.params.id,
        email: this.data.email,
        phone: this.data.phone,
        name: this.data.name,
        status: this.data.status,
      };

      this.$confirm("是否确定更改", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "更改",
        cancelButtonText: "放弃更改",
      })
        .then(() => {
          partner_account_update(temp).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功更改");
              this.$router.push({ name: "partnerAccountManage" });
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃更改" : "停留在当前",
          });
        });
    },
  },
};
</script>

<style type="scss">
.a {
  margin-left: 20%;
}
</style>
